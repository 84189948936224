import './App.css';
import { useState } from 'react';

function App() {
  const today = new Date().toISOString().split('T')[0];
  const [startAmount, setStartAmount] = useState(100);
  const [endAmount, setEndAmount] = useState('100,000');
  const [endDate, setEndDate] = useState(today);
  // const [option, setOption] = useState({});
  // const generateList = () => {
  //   setOption({
  //     startDate: '2023-10-29',
  //     endDate: '2023-12-12',
  //     startAmount,
  //     step: 0.05,
  //   });
  // };
  const formatNumber = (value) => {
    const commaRemovedValue = Number(value.replaceAll(',', ''));
    return commaRemovedValue.toLocaleString();
  };

  return (
    <div>
      <span>나는 </span>
      <input
        value={endDate}
        type="date"
        placeholder="종료일"
        className="input w-40 h-5 mx-1 "
        onChange={(e) => setEndDate(e.target.value)}
      />
      <span>까지 </span>
      <input
        value={startAmount}
        onChange={(e) => setStartAmount(formatNumber(e.target.value))}
        type="text"
        placeholder="시작 금액"
        className="input w-24 h-5 mx-1"
      />
      <span>달러로 </span>
      <input
        value={endAmount}
        onChange={(e) => setEndAmount(formatNumber(e.target.value))}
        type="text"
        placeholder="목표 금액"
        className="input w-24 h-5 mx-1"
      />
      <span>를 벌고 싶다</span>
    </div>
  );
}

export default App;
